<template>
  <div>
    <!-- 首页维护页面 -->
    <Header title="首页信息" index="首页" titleOne="官网管理" beforeTitle="首页" />
    <div class="content">
      <el-table
        v-loading="loading"
        element-loading-text="正在加载/更新中···"
        :data="dataList"
        style="width: 100%"
      >
        <el-table-column prop="url" label="图片" width="300px">
          <template slot-scope="scope">
            <el-popover placement="top-start" title trigger="hover" >
              <img :src="gqbpath + scope.row.url" alt style="max-height: 180px;" />
              <img slot="reference" :src="gqbpath + scope.row.url" style="max-height: 90px;" />
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column prop="size" label="图片规范 / 大小（单位 px）"></el-table-column>
        <el-table-column prop="update_time" label="更新时间">
          <template slot-scope="scope">{{ scope.row.update_time ? scope.row.update_time : '-' }}</template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-upload
              :http-request="httpBanner"
              class="upload-demo"
              action="#"
              :limit="1"
              :before-upload="beforeAvatarUpload"
              :on-exceed="handleExceed"
              :file-list="fileList"
              :show-file-list="false"
            >
              <el-button size="small" class="edit" @click="editBanner(scope.row.id)">更新</el-button>
            </el-upload>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import Gqburl from '../../components/global.vue'
export default {
  data () {
    return {
      fileList: [],//上传列表文件
      loading: true,
      gqbpath: Gqburl.userSite,
      dataList: [],
      banner_id: Number,
    }
  },
  created () {
    this.getList()

  },
  methods: {
    httpBanner (params) {
      console.log(params, '上传文件参数')
      this.loading = true
      let file = params.file;
      const form = new FormData();
      form.append("file", file);
      form.append("id", this.banner_id);
      this.upload("/api/website/update_banner", form).then((a) => {
        console.log(a, '更新回调',this.fileList)
        this.getList()
        setTimeout(() => {
          this.$message.success('更新成功')
          this.loading = false
        }, 500)
      })
    },
    getList () {
      this.axios.get('/api/website/get_banner_list').then((res) => {
        this.dataList = res.data.data
        this.loading = false
      })
    },
    upload (url, params) {
      return axios
        .post(url, params, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          return new Promise((resolve, reject) => {
            resolve(res)
          })
        })
        .catch((err) => {
          console.log('err:', err)
        })
    },
    editBanner (id) {
      this.banner_id = id
    },
    beforeAvatarUpload () {

    },
    handleExceed (img) {
      this.$message.warning(`当前限制选择 1 个图片 `);
      // const isJPG =
      //   img.type === 'image/png' || 'image/jpg' || 'image/jpeg'

      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!')
      // }
      // return isJPG && isLt2M
    },
  }


}
</script>
<style scoped></style>